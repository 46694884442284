import { ArrowDropDown, Create } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import Tick from 'containers/car-services-order/components/Tick';
import { PartItem } from 'containers/car-services-order/requests';

export type BundleStatusTypes = 'ERROR' | 'COMPLETED' | 'NONE';

export const CustomPartSelectorItem = ({
  title,
  onClick,
  state,
  brand,
}: {
  title: string;
  onClick: () => void;
  state: BundleStatusTypes;
  brand: PartItem | undefined;
}) => {
  return (
    <Stack
      sx={{
        borderRadius: '0.5rem',
        border: (theme) =>
          `${1 / 16}rem solid ${
            state === 'ERROR'
              ? theme.palette.error.icon
              : theme.palette.surface.paper
          }`,
        padding: '0.75rem 1rem',
        marginBottom: '0.5rem',
      }}
      onClick={onClick}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
          <Tick
            sx={{
              margin: 0,
              color: (theme) =>
                state === 'COMPLETED'
                  ? theme.palette.success.icon
                  : state === 'ERROR'
                  ? theme.palette.error.icon
                  : theme.palette.iconDeactive.main,
            }}
            isactive={String(state === 'COMPLETED')}
          />
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 600,
              lineHeight: '2rem',
              marginLeft: '1rem',
            }}
          >
            {title}
          </Typography>
        </Stack>
        {state === 'COMPLETED' ? (
          <Create
            sx={{
              color: (theme) => theme.palette.iconDefault.main,
            }}
          />
        ) : (
          <ArrowDropDown
            sx={{
              color: (theme) => theme.palette.iconDeactive.main,
            }}
          />
        )}
      </Stack>
      {brand && (
        <>
          <Typography
            sx={{
              marginLeft: '2.5rem',
              fontSize: '0.75rem',
              fontWeight: 400,
              lineHeight: '1.5rem',
              marginTop: '0.75rem',
            }}
          >
            {brand?.name}
          </Typography>
          <Typography
            sx={{
              fontSize: '0.75rem',
              fontWeight: 600,
              lineHeight: '1.75rem',
              alignSelf: 'end',
            }}
          >
            {brand?.market_price.toLocaleString()}{' '}
            <Typography
              component="span"
              sx={{
                fontSize: '0.625rem',
                fontWeight: 400,
                lineHeight: '1.25rem',
              }}
            >
              تومان
            </Typography>
          </Typography>
        </>
      )}
    </Stack>
  );
};
