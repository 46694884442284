import {
  ConfirmationNumber,
  ConfirmationNumberOutlined,
  DeleteOutlined,
} from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { type FocusEvent, useState } from 'react';
import { DiscountCode } from 'services/rest/car-services/car-services';
import { scrollView } from 'utils/scrollView';

const Discount = ({
  onChange,
  onSubmitDiscount,
  onDeleteDiscount,
  discountAmount,
  isValidCode,
  defaultCode,
  defaultInputValue,
  isLoading,
}: {
  onSubmitDiscount: (code: string) => void;
  onDeleteDiscount: (code: string) => void;
  isValidCode: boolean;
  discountAmount: string | undefined;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  defaultCode: DiscountCode[] | null | undefined;
  defaultInputValue: string;
  isLoading?: boolean;
}) => {
  const theme = useTheme();
  const [code, setCode] = useState<string>(defaultInputValue ?? '');

  return (
    <Stack
      sx={{
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Stack width={'100%'} direction="column" gap={2}>
        <Stack
          width={'100%'}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Typography
            fontSize={'14px'}
            fontWeight={600}
            color={theme.palette.common.black}
          >
            کد تخفیف دارید؟
          </Typography>

          {/* <Stack direction="row" gap={1} alignItems="center">
            <Add
              sx={{
                color: theme.palette.primary.main,
                width: theme.spacing(2),
                height: theme.spacing(2),
              }}
            />
            <Typography
              fontSize={'12px'}
              fontWeight={400}
              color={theme.palette.primary.main}
            >
              افزودن کد تخفیف
            </Typography>
          </Stack> */}
        </Stack>
        <Stack sx={{ width: '100%' }}>
          {(isValidCode && code) || (defaultCode?.length ?? 0) > 0 ? (
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'start',
                width: '100%',
              }}
            >
              <Stack sx={{ flexDirection: 'row' }}>
                <ConfirmationNumberOutlined
                  sx={{
                    color: theme.palette.iconDefault.main,
                    marginRight: '1rem',
                  }}
                />
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '0.8125rem',
                      fontWeight: '500',
                      marginBottom: '0.5rem',
                    }}
                  >
                    {defaultCode?.[0]?.code ?? code}
                  </Typography>
                  {defaultCode ? (
                    <Typography
                      sx={{
                        fontSize: '0.75rem',
                        fontWeight: '500',
                        color: theme.palette.iconDefault.main,
                      }}
                    >{`${defaultCode[0]?.amount.toLocaleString()} تومان از هزینه کل کسر شد.`}</Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: '0.75rem',
                        fontWeight: '500',
                        color: theme.palette.iconDefault.main,
                      }}
                    >{`${
                      discountAmount ?? '0'.toLocaleString()
                    } تومان از هزینه کل کسر شد.`}</Typography>
                  )}
                </Stack>
              </Stack>
              <DeleteOutlined
                sx={{
                  cursor: 'pointer',
                  padding: '0 0 0.125rem 0.125rem',
                  width: '1.625rem',
                  height: '1.625rem',
                }}
                onClick={() => {
                  onDeleteDiscount(defaultCode?.[0].code ?? code);
                }}
              />
            </Stack>
          ) : (
            <>
              {/* {isChekcDiscountCodeLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    inset: 0,
                    borderRadius: 3,
                    zIndex: 999,
                  }}
                >
                  <BarLoader width="10%" height="2px" color="gray" />
                </Box>
              ) : null} */}
              <TextField
                color="secondary"
                autoFocus={false}
                placeholder="کد تخفیف"
                label="کد تخفیف"
                title="کد تخفیف خود را وارد کنید."
                size="small"
                name="discount"
                fullWidth={true}
                value={code}
                onFocus={(e: FocusEvent<HTMLInputElement>) => {
                  scrollView(
                    e.target?.parentElement?.parentElement?.parentElement,
                  );
                }}
                onChange={(event) => {
                  setCode(event.target.value);
                  onChange?.(event);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ConfirmationNumber />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <Button
                          color="secondary"
                          size="small"
                          variant="text"
                          disabled={!code}
                          sx={{
                            minHeight: theme.spacing(2),
                            marginRight: -0.75,
                          }}
                          onClick={() => {
                            onSubmitDiscount(code);
                          }}
                        >
                          ثبت کد تخفیف
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Discount;
