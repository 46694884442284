import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { Car } from 'services/rest/car';
import {
  EvaluationCompanionTimeList200DataItem,
  EvaluationV1PackagesList200DataItem,
  EvaluationV1PackagesListCOI200DataItem,
  EvaluationV1ServiceTimeList200DataItem,
} from 'services/rest/car-inspection/carInspectionAPIs-new';
import { CitiesV1NeighborhoodRead200DataItem } from 'services/rest/car-inspection/carInspectionAPIs.schemas';
import { CitiesV1List200DataItem } from 'services/rest/car-inspection/cities-new';
import { InspectionCenterItem } from 'services/rest/car-inspection/evaluation-new';
import { TestVariantType } from 'utils/abTest';
import { getUserPhone } from 'utils/auth';

export type CarInspectionStep =
  | '00-car-info'
  | '01-location-info'
  | '02-services-info'
  | '03-service-time'
  | '04-user-info'
  | '05-user-otp'
  | '06-invoice';

export type CIPSteps =
  | '00-terms-start'
  | '01-enough-light'
  | '02-dry-clean'
  | '03-empty-trunk'
  | '04-rain-ceil'
  | '05-owner-number'
  | '06-thank-you';

// export interface Car {
//   id: string;
//   name: string;
//   name_en: string;
//   lastLevel: string;
//   lastLevelId: number;
//   type: string;
// }

export type VehicleType = 'light_car' | 'motor_cycle';

export interface CarInspectionPageState {
  step: CarInspectionStep | undefined;
  userOrder?: {
    car?: Car;
    city?: CitiesV1List200DataItem;
    neighborhood?: CitiesV1NeighborhoodRead200DataItem;
    plan?:
      | EvaluationV1PackagesList200DataItem
      | EvaluationV1PackagesListCOI200DataItem;
    serviceTime?:
      | EvaluationV1ServiceTimeList200DataItem
      | EvaluationCompanionTimeList200DataItem;
    center?: InspectionCenterItem;
    fullName?: string;
    phoneNumber?: string;
    address?: string;
    otp?: string;
    discountCode?: string;
    discountPrice?: number;
    totalPrice?: number;
    reserveId?: number;
    serviceID?: string;
    serviceLocation?: string;
    hasTOS?: boolean;
    visitTime?: string;

    // ToDo: remve from hear ASAP
    carLevel?: number;
    COIBrandModel?: string;
  };
  divarToken?: string;
  testFlag?: TestVariantType;
  vehicleType: VehicleType;
  isLoading: boolean;
  paymentStep: CIPSteps;
  ownerPhoneNumber?: string;

  // ToDo: remve from hear ASAP

  aggreeRules?: boolean;
  //order from divar chat data
  divarChatInfoId?: string;
  chatRequestData?: string;
}

const initialState: CarInspectionPageState = {
  step: undefined,
  userOrder: {
    // serviceID: 'کارشناسی استاندارد',
    // serviceLocation: 'در محل شما',
    phoneNumber: getUserPhone(),
  },
  isLoading: true,
  paymentStep: '06-thank-you',
  vehicleType: 'light_car',
};

const carInspectionSlice = createSlice({
  name: 'carInspectionPage',
  initialState,
  reducers: {
    setCIEmpty: (state) => {
      state.userOrder = initialState.userOrder;
      state.aggreeRules = false;
      state.isLoading = initialState.isLoading;
      state.paymentStep = initialState.paymentStep;
      state.vehicleType = initialState.vehicleType;
    },
    setCIUserOrder: (
      state,
      action: PayloadAction<CarInspectionPageState['userOrder']>,
    ) => {
      state.userOrder = {
        ...state.userOrder,
        ...action.payload,
      };
    },
    setCIStep: (
      state,
      action: PayloadAction<CarInspectionPageState['step']>,
    ) => {
      state.step = action.payload;
    },
    setIsLoading: (
      state,
      action: PayloadAction<CarInspectionPageState['isLoading']>,
    ) => {
      state.isLoading = action.payload;
    },
    setCIPaymentStep: (
      state,
      action: PayloadAction<CarInspectionPageState['paymentStep']>,
    ) => {
      state.paymentStep = action.payload;
    },
    setCIOwnerPhoneNumber: (
      state,
      action: PayloadAction<CarInspectionPageState['ownerPhoneNumber']>,
    ) => {
      state.ownerPhoneNumber = action.payload;
    },
    setCIDivarToken: (
      state,
      action: PayloadAction<CarInspectionPageState['divarToken']>,
    ) => {
      state.divarToken = action.payload;
    },
    setCITestFlag: (
      state,
      action: PayloadAction<CarInspectionPageState['testFlag']>,
    ) => {
      state.testFlag = action.payload;
    },
    setCIVehicleType: (
      state,
      action: PayloadAction<CarInspectionPageState['vehicleType']>,
    ) => {
      state.vehicleType = action.payload;
    },
    setCIAggreeRules: (
      state,
      action: PayloadAction<CarInspectionPageState['aggreeRules']>,
    ) => {
      state.aggreeRules = action.payload;
    },
    setDivarChatInfoId: (state, action: PayloadAction<string | undefined>) => {
      state.divarChatInfoId = action.payload;
    },
    setDivarChatRequestData: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.chatRequestData = action.payload;
    },
  },
});

export const {
  setCIUserOrder,
  setCIStep,
  setIsLoading,
  setCIPaymentStep,
  setCIOwnerPhoneNumber,
  setCIDivarToken,
  setCIVehicleType,
  setCITestFlag,
  setCIAggreeRules,
  setCIEmpty,
  setDivarChatInfoId,
  setDivarChatRequestData,
} = carInspectionSlice.actions;

export const useDispatchCIUserOrder = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['userOrder']) =>
    dispatch(setCIUserOrder(state));
};

export const useDispatchCIStep = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['step']) => dispatch(setCIStep(state));
};

export const useDispatchCIIsLoading = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['isLoading']) =>
    dispatch(setIsLoading(state));
};

export const useDispatchCIPaymentStep = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['paymentStep']) =>
    dispatch(setCIPaymentStep(state));
};

export const useDispatchCIDivarToken = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['divarToken']) =>
    dispatch(setCIDivarToken(state));
};

export const useDispatchCIVehicleType = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['vehicleType']) =>
    dispatch(setCIVehicleType(state));
};

export const useDispatchCITestFlag = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['testFlag']) =>
    dispatch(setCITestFlag(state));
};

export const useDispatchCIAggreeRules = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['aggreeRules']) =>
    dispatch(setCIAggreeRules(state));
};

export const useDispatchCIDivarChatInfoId = () => {
  const dispatch = useAppDispatch();
  return (value: CarInspectionPageState['divarChatInfoId']) =>
    dispatch(setDivarChatInfoId(value));
};

export const useDispatchCIDivarChatRequestData = () => {
  const dispatch = useAppDispatch();
  return (value: CarInspectionPageState['chatRequestData']) =>
    dispatch(setDivarChatRequestData(value));
};

export const useEmptyCI = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(setCIEmpty());
};

export const useCIAggreeRules = () =>
  useAppSelector((state) => state.carInspection.aggreeRules);

export const useCIUserOrder = () =>
  useAppSelector((state) => state.carInspection.userOrder);

export const useCITestFlag = () =>
  useAppSelector((state) => state.carInspection.testFlag);

export const useCIStep = () =>
  useAppSelector((state) => state.carInspection.step);

export const useCIIsLoading = () =>
  useAppSelector((state) => state.carInspection.isLoading);

export const useCIPaymentStep = () =>
  useAppSelector((state) => state.carInspection.paymentStep);

export const useCIDivarToken = () =>
  useAppSelector((state) => state.carInspection.divarToken);

export const useCIVehicleType = () =>
  useAppSelector((state) => state.carInspection.vehicleType);

export const useCIDivarChatInfoId = () =>
  useAppSelector((state) => state.carInspection.divarChatInfoId);

export const useCIDivarChatRequestData = () =>
  useAppSelector((state) => state.carInspection.chatRequestData);

export default carInspectionSlice.reducer;
