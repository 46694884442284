import axios from 'axios';
import ENV from 'constants/environments';
import Cookies from 'universal-cookie';

export const KARNEMEH_COOKIE_KEY_NAME = 'karnameh-access-token';
export const KARNAMEH_COOKIE_DOMAIN_VALUE =
  process.env.NODE_ENV === 'development'
    ? 'localhost'
    : `.${new URL(ENV.LANDING_URL).host}`;

const cookie = new Cookies();

export function getToken(): string {
  const token = cookie.get(KARNEMEH_COOKIE_KEY_NAME);

  if (token) axios.defaults.headers.common.authorization = `Bearer ${token}`;

  return token;
}

export type JWTDataDTO = {
  user_id: string;
  first_name: string;
  last_name: string;
  username: string;
  exp: number;
  roles_in_services: string[];
};

function parseToken(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export function saveToken(token: string) {
  const tokenData = parseToken(token) as JWTDataDTO;
  const expires = new Date(tokenData.exp * 1000);
  cookie.set(KARNEMEH_COOKIE_KEY_NAME, token, {
    domain: KARNAMEH_COOKIE_DOMAIN_VALUE,
    path: '/',
    expires,
  });
  axios.defaults.headers.common.authorization = `Bearer ${token}`;
}

export function getUserDataFromToken() {
  const token = getToken();
  if (!token) return undefined;
  return parseToken(token) as JWTDataDTO;
}

export function removeToken() {
  // karnameh-authorization
  cookie.remove(KARNEMEH_COOKIE_KEY_NAME, {
    domain: '.karnameh.com',
  });
  cookie.remove(KARNEMEH_COOKIE_KEY_NAME, {
    domain: KARNAMEH_COOKIE_DOMAIN_VALUE,
  });
  axios.defaults.headers.common.uthorization = '';
}

export function isUserLoggedIn() {
  return getToken() !== undefined && getToken() !== null && getToken() !== '';
}

export function getUserPhone() {
  try {
    const payload = getToken().split('.')[1];
    const docodedPayload = Buffer.from(payload, 'base64').toString();
    return (
      typeof JSON.parse(docodedPayload).user === 'string'
        ? JSON.parse(docodedPayload).user
        : ''
    ) as string;
  } catch (e) {
    return '';
  }
}

export const isUserTokenExpired = () => {
  try {
    const payload = getToken().split('.')[1];
    const docodedPayload = Buffer.from(payload, 'base64').toString();
    const exp = (
      typeof JSON.parse(docodedPayload).exp === 'number'
        ? JSON.parse(docodedPayload).exp
        : ''
    ) as string;
    const isExpired = parseInt(exp) < Date.now() / 1000;
    if (isExpired) removeToken();

    return isExpired;
  } catch (e) {
    return '';
  }
};
if (typeof window !== 'undefined')
  (window as any).checkToken = isUserTokenExpired;

export function getExpertId() {
  try {
    const payload = getToken().split('.')[1];
    const docodedPayload = Buffer.from(payload, 'base64').toString();
    return (
      typeof JSON.parse(docodedPayload).expert === 'number'
        ? String(JSON.parse(docodedPayload).expert)
        : ''
    ) as string;
  } catch (e) {
    return '';
  }
}
