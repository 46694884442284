import { Check, Close } from '@mui/icons-material';
import {
  Drawer,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { ShiftTimeResponse } from 'containers/car-services-order/requests';
import { useEffect, useMemo, useState } from 'react';
import theme from 'theme';

type Props = {
  open: boolean;
  onClose: () => void;
  onSelect: (time: Date) => void;
  data: ShiftTimeResponse;
  defaultValue: Date | undefined;
};

const Header = ({ title, onClose }: { title: string; onClose: () => void }) => {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'relative',
        padding: '1.63rem 1rem 1.13rem',
        borderBottom: '1px solid #c9cccf',
      }}
    >
      <Typography
        sx={{
          fontSize: '1rem',
          fontWeight: 600,
          lineHeight: '1.75rem',
        }}
      >
        {title}
      </Typography>

      <IconButton
        component={Close}
        sx={{
          position: 'absolute',
          left: '1rem',
          top: '50%',
          padding: 0,
          width: '1.5rem',
          height: '1.5rem',
          transform: 'translateY(-50%)',
          color: theme.palette.iconDefault.main,
        }}
        onClick={onClose}
      />
    </Stack>
  );
};

const weekDays = [
  'شنبه',
  'یک‌شنبه',
  'دوشنبه',
  'سه‌شنبه',
  'چهارشنبه',
  'پنج‌شنبه',
  'جمعه',
];

const getDayText = (time: string) => {
  const date = new Date(parseInt(time));
  date.setHours(0, 0, 0, 0);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  ) {
    return 'امروز';
  } else if (date.getTime() === today.getTime() + 24 * 3600000) {
    return 'فردا';
  } else {
    return weekDays[(date.getDay() + 1) % 7];
  }
};

export const ShiftTimeBottomSheet = ({
  open,
  onClose,
  onSelect,
  data,
  defaultValue,
}: Props) => {
  const firstShiftDay = new Date(Object.keys(data)[0]);
  firstShiftDay.setHours(0, 0, 0, 0);
  const defValue = defaultValue
    ? new Date(defaultValue.getTime())
    : firstShiftDay;
  const [selectedDate, setSelectedDate] = useState(String(defValue.getTime()));
  const [selectedShift, setSelectedShift] = useState<string>('');

  const selectedShiftDay = useMemo(
    () =>
      Object.keys(data).find((itemKey) => {
        const itemDate = new Date(itemKey);
        itemDate.setHours(0, 0, 0, 0);
        return String(itemDate.getTime()) === selectedDate;
      }),
    [data, selectedDate],
  );

  useEffect(() => {
    if (!defaultValue) return;
    const temp = new Date(defaultValue.getTime());
    setSelectedShift(
      String(
        `${String(temp.getHours()).padStart(2, '0')}:${String(
          temp.getMinutes(),
        ).padEnd(2, '0')}:00`,
      ),
    );
    temp.setHours(0, 0, 0, 0);
    setSelectedDate(String(temp.getTime()));
  }, [defaultValue]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      sx={{
        '& .MuiPaper-root': {
          borderTopLeftRadius: '28px',
          borderTopRightRadius: '28px',
          backgroundColor: 'white',
          height: 'fit-content',
          maxHeight: '80%',
          minHeight: '20%',
          overflow: 'hidden',
        },
      }}
    >
      <Header title="انتخاب زمان" onClose={onClose} />
      <Stack sx={{ padding: '1.75rem 1.5rem' }}>
        <Stack
          sx={{
            display: 'grid',

            gridTemplateColumns: 'repeat(4, 1fr)',
            flexDirection: 'row',
            borderRadius: '6.25rem',
            border: '1px solid #C9CCCF',
            overflow: 'hidden',
          }}
        >
          {Object.keys(data).map((itemKey, index) => {
            if (index > 3) return;
            const shiftDateObj = new Date(itemKey);
            shiftDateObj.setHours(0, 0, 0, 0);
            const shiftDay = String(shiftDateObj.getTime());

            return (
              <Stack
                onClick={() => {
                  setSelectedDate(shiftDay);
                }}
                key={index}
                sx={{
                  borderRight:
                    index === Object.keys(data).length - 1
                      ? 'none'
                      : '1px solid #C9CCCF',
                  flexDirection: 'row',
                  padding: '0.62rem 0.75rem',
                  justifyContent: 'center',
                  backgroundColor:
                    selectedDate === shiftDay
                      ? theme.palette.secondary.main
                      : theme.palette.common.white,
                  color:
                    selectedDate === shiftDay
                      ? theme.palette.common.white
                      : theme.palette.common.black,
                }}
              >
                {selectedDate === shiftDay && (
                  <Check
                    sx={{
                      width: '1.13rem',
                      height: '1.13rem',
                      marginRight: '0.5rem',
                    }}
                  />
                )}
                <Typography
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 600,
                    lineHeight: '1.25rem',
                  }}
                >
                  {getDayText(shiftDay)}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
        <Stack
          sx={{
            padding: '0 1rem',
            borderRadius: '1rem',
            border: `1px solid ${theme.palette.surface.paper}`,
            marginTop: '1.88rem',
            minHeight: '10rem',
          }}
        >
          <RadioGroup
            value={selectedShift}
            defaultValue={
              defaultValue
                ? `${defaultValue.getHours()}:${defaultValue
                    .getMinutes()
                    .toString()
                    .padEnd(2, '0')}:00`
                : undefined
            }
          >
            {selectedShiftDay &&
              data[selectedShiftDay]?.map((item, index) => {
                const itemDate = new Date(selectedDate);
                const hours = item.start_time
                  .split(':')
                  .map((i) => parseInt(i));
                itemDate.setHours(hours[0], hours[1], hours[2], 0);

                return (
                  <Stack
                    key={index}
                    sx={{
                      flexDirection: 'row',
                      padding: '0.97rem 0',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.surface.paper}`,
                    }}
                    onClick={() => {
                      setSelectedShift(item.start_time);
                      const tempSelectedDateShift = new Date(
                        parseInt(selectedDate),
                      );
                      const tempHours = item.start_time
                        .split(':')
                        .map((i) => parseInt(i));
                      tempSelectedDateShift.setHours(
                        tempHours[0],
                        tempHours[1],
                        tempHours[2],
                        0,
                      );
                      onSelect(tempSelectedDateShift);
                    }}
                  >
                    <Radio
                      value={item.start_time}
                      sx={{
                        marginRight: '1rem',
                        width: '1.5rem',
                        height: '1.5rem',
                        color: theme.palette.secondary.main,
                        '&.Mui-checked': {
                          color: theme.palette.secondary.main,
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: '1rem',
                        lineHeight: '1.5rem',
                        fontWeight: 400,
                      }}
                    >{`ساعت ${item.start_time.slice(
                      0,
                      -3,
                    )} تا ${item.end_time.slice(0, -3)}`}</Typography>
                  </Stack>
                );
              })}
          </RadioGroup>
          {data[selectedShift]?.length === 0 && (
            <Typography
              sx={{
                margin: 'auto',
                fontSize: '1rem',
                fontWeight: 600,
                lineHeight: '1.75rem',
                textAlign: 'center',
                color: theme.palette.iconDefault.main,
              }}
            >
              سرویس خدمات دوره‌ای در این روز انجام نمی‌شود.
            </Typography>
          )}
        </Stack>
      </Stack>
    </Drawer>
  );
};
