import { ArrowForwardRounded, CloseRounded } from '@mui/icons-material';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { OTP_LENGTH } from 'constants/general';
import { Dispatch, FC, SetStateAction } from 'react';
import OTPInput from 'react-otp-input';
import styles from './styles';
import useLogic from './useLogic';

interface PropTypes {
  setOtpEnteredLength: Dispatch<SetStateAction<number>>;
}

const GetUserOtp: FC<PropTypes> = (props) => {
  const { setOtpEnteredLength } = props;

  const {
    phoneNumber,
    modalState,
    errorMessage,
    timerSeconds,
    theme,
    formik,
    changeStepToPhoneNumber,
    closeModal,
    resendOtp,
    otpChangeHandler,
    canSubmit,
  } = useLogic(setOtpEnteredLength);

  return (
    <>
      <Stack
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
        sx={styles.container}
      >
        <Stack sx={styles.header}>
          {modalState === 'just-otp' && (
            <IconButton onClick={closeModal} sx={styles.backButton}>
              <CloseRounded />
            </IconButton>
          )}
          {modalState === 'otp' && (
            <IconButton
              onClick={changeStepToPhoneNumber}
              sx={styles.backButton}
            >
              <ArrowForwardRounded />
            </IconButton>
          )}
          <Typography fontSize="1.25rem" fontWeight={600}>
            کد تایید
          </Typography>
        </Stack>
        <Stack sx={styles.contents}>
          <Typography fontSize="0.875rem" mt={{ xs: '2rem', md: '1rem' }}>
            کد ارسالی به شماره {phoneNumber} را وارد کنید.
          </Typography>

          <OTPInput
            value={formik.values.otp}
            onChange={otpChangeHandler}
            renderInput={({
              type: _type,
              className: _className,
              ...inputProps
            }) => (
              <input
                type="tel"
                className="modal-login-otp-input"
                {...inputProps}
              />
            )}
            containerStyle={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'center',
              alignSelf: 'center',
              marginTop: '1rem',
            }}
            shouldAutoFocus
            numInputs={OTP_LENGTH}
          />
          <style>{`
            .modal-login-otp-input {
              flex-grow: 1;
              height: 2.75rem;
              outline: none;
              border: 1px solid #c9cccf;
              border-radius: 8px;
              margin-right: 0.5rem;
              margin-left: 0.5rem;
              text-align: center;
              font-family: YekanBakh, sans-serif;
            }
            .modal-login-otp-input:focus {
              border-color: ${theme.palette.secondary.main};
              border-width: 2px;
            }
          `}</style>

          {!!errorMessage && (
            <Typography
              fontWeight={600}
              color="error"
              mt="1rem"
              fontSize="0.75rem"
            >
              {errorMessage}
            </Typography>
          )}

          {modalState === 'otp' && (
            <Stack direction="row" alignItems="center" mt="0.5rem">
              <Typography
                fontSize="0.75rem"
                mr="0.5rem"
                color={(th) => th.palette.iconDefault.main}
              >
                شماره موبایل اشتباه است؟
              </Typography>
              <Typography
                color="primary"
                fontSize="0.75rem"
                sx={{
                  borderBottom: (th) => `1px dashed ${th.palette.primary.main}`,
                  cursor: 'pointer',
                }}
                onClick={changeStepToPhoneNumber}
              >
                ویرایش شماره
              </Typography>
            </Stack>
          )}
        </Stack>

        <Stack sx={styles.specer} />
        <Stack sx={styles.submitContainer}>
          <Button
            onClick={() => formik.handleSubmit()}
            type="submit"
            disabled={!canSubmit}
            sx={styles.submitButton}
          >
            تائید و ادامه
          </Button>
          {timerSeconds === 0 && (
            <Button
              onClick={resendOtp}
              sx={styles.sendCodeButton}
              variant="outlined"
            >
              ارسال مجدد کد
            </Button>
          )}
          {timerSeconds > 0 && (
            <Typography
              mr={{ xs: 'unset', md: '0.25rem' }}
              mt={{ xs: '1rem', md: 'unset' }}
              flexGrow={1}
              flexBasis="50%"
              fontSize="0.75rem"
            >
              درخواست مجدد ({Math.floor(timerSeconds / 60)}:{timerSeconds % 60})
            </Typography>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default GetUserOtp;
