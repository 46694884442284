import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { apiClient } from '../../clients';
import { switchEndpoints } from '../../configs';

export const logout = () => {
  return apiClient<SendOtpResponse>({
    method: 'POST',
    url: switchEndpoints.LOGOUT,
  });
};

export const logoutKey = ['logout'];

export const useLogoutUser = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof logout>>,
      unknown,
      undefined,
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) => {
  return useMutation({
    ...options,
    mutationKey: logoutKey,
    mutationFn: logout,
  });
};

//#region SubmitPhoneNumber
export const postLoginSubmitPhoneNumber = (
  params: PostLoginSubmitPhoneNumberParams,
) => {
  return apiClient<PostLoginSubmitPhoneNumberResponse>({
    url: switchEndpoints.SEND_OTP,
    method: 'post',
    data: params,
  }).then((res) => res.data);
};

export const usePostLoginSubmitPhoneNumber = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof postLoginSubmitPhoneNumber>>,
    unknown,
    PostLoginSubmitPhoneNumberParams
  >,
) => {
  const mutationFn = (params: PostLoginSubmitPhoneNumberParams) => {
    return postLoginSubmitPhoneNumber(params);
  };

  return useMutation({
    mutationFn,
    ...options,
  });
};
//#endregion

//#region SubmitOtp
export const postLoginSubmitOtp = (params: PostLoginSubmitOtpParams) => {
  return apiClient<PostLoginSubmitOtpResponse>({
    url: switchEndpoints.CONFIRM_OTP,
    method: 'post',
    data: params,
  }).then((res) => res.data);
};

export const usePostLoginSubmitOtp = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof postLoginSubmitOtp>>,
    unknown,
    PostLoginSubmitOtpParams
  >,
) => {
  const mutationFn = (params: PostLoginSubmitOtpParams) => {
    return postLoginSubmitOtp(params);
  };

  return useMutation({
    mutationFn,
    ...options,
  });
};
//#endregion
