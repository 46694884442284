import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GlobalState {
  userInfo: {
    phoneNumber?: string;
  };
}

const initialState: GlobalState = { userInfo: {} };

const { reducer, actions } = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobalUserInfo: (
      state,
      action: PayloadAction<GlobalState['userInfo']>,
    ) => {
      state.userInfo = action.payload;
    },
  },
});

export const { setGlobalUserInfo } = actions;
export default reducer;
