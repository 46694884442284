import { Phone } from '@mui/icons-material';
import { Stack, Typography, useTheme } from '@mui/material';
import { receiptTextStyles } from 'containers/car-services-order/configs';

const CallToSupport = () => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0.5rem',
        margin: '1.5rem 0',
        color: theme.palette.secondary.main,
      }}
      component="a"
      href="tel:02179237"
    >
      <Typography sx={{ ...receiptTextStyles.title }}>
        نیاز به مشاوره دارم:
      </Typography>
      <Phone
        sx={{
          color: theme.palette.secondary.main,
          width: '1.25rem',
          height: '1.25rem',
        }}
      />
      <Typography component="bdi" sx={{ ...receiptTextStyles.title }}>
        ۰۲۱ - ۷۹ ۲۳۷
      </Typography>
    </Stack>
  );
};

export default CallToSupport;
