import { Download } from '@mui/icons-material';
import { Container, Link, Stack, Typography } from '@mui/material';
import AppLink from 'components/AppLink';
import { statics } from 'layout/Footer/data';
import styles from 'layout/Footer/styles';
import HeaderLogo from 'layout/Header/HeaderLogo';
import Image from 'next/image';
import { type FC } from 'react';
import theme from 'theme';
import bazarBtn from '../../../public/assets/images/home/bazar-download.webp';
import myketButton from '../../../public/assets/images/home/myket-download.svg';

const { footerData, socialData, signs } = statics;

const FooterSigns: FC = () => {
  return (
    <Stack sx={styles.signsContainer}>
      <Stack
        sx={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1.5rem',
        }}
      >
        {Object.keys(signs).map((sign: string) =>
          sign === 'enamad' ? (
            <Link
              style={{ width: '3.48rem', height: '3rem' }}
              target="_blank"
              key={`${signs[sign].link}-${sign}`}
              href={signs.enamad.linkProps.href}
            >
              {signs[sign].imageProps && (
                // eslint-disable-next-line @next/next/no-img-element
                <Stack
                  sx={{
                    width: '3.48rem',
                    height: '3rem',
                    position: 'relative',
                  }}
                >
                  <img {...signs[sign].imageProps} alt="enemad" />
                </Stack>
              )}
            </Link>
          ) : (
            <Link
              style={{ width: '3.48rem', height: '3rem' }}
              target="_blank"
              // sx={{ width: '3.48rem', height: '3rem' }}
              key={`${signs[sign].link}-${sign}`}
              href={signs[sign].link}
            >
              <Stack
                sx={{
                  width: '3.48rem',
                  height: '3rem',
                  position: 'relative',
                }}
              >
                <Image
                  src={signs[sign].image.image}
                  alt={signs[sign].image.alt}
                  sizes="100%"
                  layout="fill"
                />
              </Stack>
            </Link>
          ),
        )}
      </Stack>
      <Typography
        sx={{
          width: '100%',
          fontSize: '0.75rem',
          color: 'white',
          fontWeight: 400,
          lineHeight: '1rem',
          mt: '1rem',
          py: '1rem',
          px: '2.56rem',
          borderTop: '1px solid white',
          textAlign: 'center',
        }}
      >
        کلیه حقوق مادی و معنوی این وب سایت متعلق به شرکت خودرو پردازش هوشمند
        است.
      </Typography>
    </Stack>
  );
};

const MobileFooter: FC = () => {
  return (
    <Stack sx={styles.mobileFooterContainer}>
      <Stack
        sx={{
          flexDirection: 'column',
          paddingX: '1.5rem',
          justifyContent: 'start',
          alignItems: 'start',
        }}
      >
        <HeaderLogo hasTell />
        <Stack
          sx={{
            justifyContent: 'space-between',
            justifyItems: 'center',
            width: '100%',
            flexDirection: 'row',
            gap: '2rem',
            marginTop: '2.38em',
          }}
        >
          <Stack
            sx={{
              width: '100%',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.75rem',
                color: (t) => t.palette.text.primary,
                fontWeight: 600,
                lineHeight: '1rem',
              }}
            >
              {footerData.services.heading}
            </Typography>
            {footerData.services.items.map((item) => {
              return (
                <AppLink
                  // sx={{ textDecoration: 'none' }}
                  passHref
                  href={item.link ?? ''}
                  key={item.title}
                >
                  <Typography
                    component={Link}
                    sx={{
                      fontSize: '0.75rem',
                      textDecoration: 'none',
                      color: (t) => t.palette.iconDefault.main,
                      fontWeight: 400,
                      lineHeight: '1rem',
                    }}
                    key={item.id}
                  >
                    {item.title}
                  </Typography>
                </AppLink>
              );
            })}
          </Stack>
          <Stack
            sx={{
              width: '100%',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.75rem',
                color: (t) => t.palette.text.primary,
                fontWeight: 600,
                lineHeight: '1rem',
              }}
            >
              {footerData.guides.heading}
            </Typography>
            {footerData.guides.items.map((item) => {
              return (
                <AppLink
                  style={{ textDecoration: 'none' }}
                  passHref={true}
                  href={item.link ?? ''}
                  key={item.title}
                >
                  <Typography
                    component={Link}
                    sx={{
                      textDecoration: 'none',
                      fontSize: '0.75rem',
                      color: (t) => t.palette.iconDefault.main,
                      fontWeight: 400,
                      lineHeight: '1rem',
                    }}
                    key={item.id}
                  >
                    {item.title}
                  </Typography>
                </AppLink>
              );
            })}
            <Stack direction={'column'} width={'100%'} gap={'1rem'}>
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  color: (t) => t.palette.text.primary,
                  fontWeight: 600,
                  lineHeight: '1rem',
                }}
              >
                {footerData.contactUs.heading}
              </Typography>
              {footerData.contactUs.items.map((item) => {
                return (
                  <AppLink
                    // sx={{ textDecoration: 'none' }}
                    href={item.link ?? ''}
                    key={item.title}
                    passHref
                  >
                    <Typography
                      component={Link}
                      sx={{
                        textDecoration: 'none',
                        fontSize: '0.75rem',
                        color: (t) => t.palette.iconDefault.main,
                        fontWeight: 400,
                        lineHeight: '1rem',
                      }}
                      key={item.id}
                    >
                      {item.title}
                    </Typography>
                  </AppLink>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1.75rem',
            gap: '1.63rem',
          }}
        >
          {socialData.reverse().map((item) => {
            const Icon = item.icon;
            return (
              <AppLink
                target="_blank"
                href={item.link}
                key={`social-${item.title}-mobile`}
                passHref
              >
                <Link>
                  <Icon />
                </Link>
              </AppLink>
            );
          })}
        </Stack>
        <Typography
          sx={{
            fontSize: '0.75rem',
            color: (t) => t.palette.text.primary,
            fontWeight: 600,
            lineHeight: '1rem',
            mt: '1.5rem',
          }}
        >
          دریافت اپلیکیشن کارنامه
        </Typography>
        <Stack
          width="100%"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          mt="2rem"
          gap="1.5rem"
          flexWrap="wrap"
        >
          <AppLink
            target="_blank"
            passHref
            href="https://cdn.karnameh.com/app-versions/karnameh-my-car-latest.apk"
          >
            <Link
              target="_blank"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                borderRadius: '0.25rem',
                border: '1px solid #00A754',
                width: '8.4rem',
                height: '2.5rem',
                gap: '0.25rem',
                textDecoration: 'none',
              }}
            >
              <Download />
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  color: (t) => t.palette.primary.main,
                  fontWeight: 400,
                  lineHeight: '1rem',
                }}
              >
                دانلود مستقیم
              </Typography>
            </Link>
          </AppLink>
          <AppLink
            passHref
            target="_blank"
            href="https://cafebazaar.ir/app/com.karnameh"
          >
            <Link
              target="_blank"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                textDecoration: 'none',
                width: '8.4rem',
                height: '2.5rem',
                gap: '0.25rem',
              }}
            >
              <Stack
                sx={{ width: '100%', height: '100%', position: 'relative' }}
              >
                <Image
                  sizes="100%"
                  layout="fill"
                  src={bazarBtn}
                  alt="captured-image"
                />
              </Stack>
            </Link>
          </AppLink>
          <AppLink
            passHref
            target="_blank"
            href="https://myket.ir/app/com.karnameh"
          >
            <Link
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                textDecoration: 'none',
                width: '8.4rem',
                height: '2.5rem',
                gap: '0.25rem',
              }}
              target="_blank"
            >
              <Stack
                sx={{ width: '100%', height: '100%', position: 'relative' }}
              >
                <Image
                  sizes="100%"
                  layout="fill"
                  src={myketButton}
                  alt="captured-image"
                />
              </Stack>
            </Link>
          </AppLink>
        </Stack>
        <Typography
          sx={{
            width: '100%',
            fontSize: '0.75rem',
            color: (t) => t.palette.text.primary,
            fontWeight: 600,
            lineHeight: '1rem',
            mt: '2rem',
            paddingTop: '1.5rem',
            borderTop: '1px solid #EBEBEB',
          }}
        >
          چرا به کارنامه اعتماد کنیم؟
        </Typography>
        <Typography
          sx={{
            width: '100%',
            fontSize: '0.75rem',
            color: (t) => t.palette.iconDefault.main,
            fontWeight: 400,
            lineHeight: '1rem',
            mt: '0.5rem',
          }}
        >
          کارنامه ارائه‌دهنده خدمات تخصصی خودرو مانند کارشناسی، خرید و فروش،
          سرویس دوره‌ای و خرید اقساطی است.علاوه بر این، برای اطلاع از قیمت روز
          خودرو، تخمین قیمت خودرو کارکرده و کارشناسی موتورسیکلت نیز می‌توانید
          روی کارنامه حساب کنید.
        </Typography>
      </Stack>
      <FooterSigns />
    </Stack>
  );
};

const DesktopFooter: FC = () => {
  return (
    <Stack
      sx={{
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      }}
      flexDirection="column"
    >
      <Stack
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        sx={{
          width: '100%',
          backgroundColor: 'white',
          paddingTop: '3.12rem',
          paddingBottom: '2rem',
          paddingX: '8rem',
        }}
      >
        <Stack sx={styles.desktopMobileFooterContainer}>
          <Stack
            sx={{
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'start',
              width: '20%',
            }}
          >
            <HeaderLogo hasTell />
          </Stack>
          <Stack
            sx={{
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'start',
              width: '20%',
              gap: '1rem',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.75rem',
                color: (t) => t.palette.text.primary,
                fontWeight: 600,
                lineHeight: '1rem',
              }}
            >
              {footerData.services.heading}
            </Typography>
            {footerData.services.items.map((item) => {
              return (
                <AppLink
                  style={{ textDecoration: 'none' }}
                  href={item.link ?? ''}
                  key={item.title}
                  passHref={true}
                >
                  <Typography
                    component={Link}
                    sx={{
                      zIndex: 0,
                      fontSize: '0.75rem',
                      color: (t) => t.palette.iconDefault.main,
                      fontWeight: 400,
                      lineHeight: '1rem',
                      textDecoration: 'none',
                    }}
                  >
                    {item.title}
                  </Typography>
                </AppLink>
              );
            })}
          </Stack>
          <Stack
            sx={{
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'start',
              width: '20%',
              gap: '1rem',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.75rem',
                color: (t) => t.palette.text.primary,
                fontWeight: 600,
                lineHeight: '1rem',
              }}
            >
              {footerData.guides.heading}
            </Typography>
            {footerData.guides.items.map((item) => {
              return (
                <AppLink passHref href={item.link ?? ''} key={item.title}>
                  <Typography
                    component={Link}
                    sx={{
                      fontSize: '0.75rem',
                      textDecoration: 'none',
                      color: (t) => t.palette.iconDefault.main,
                      fontWeight: 400,
                      lineHeight: '1rem',
                    }}
                    key={item.id}
                  >
                    {item.title}
                  </Typography>
                </AppLink>
              );
            })}
          </Stack>
          <Stack
            sx={{
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'start',
              width: '20%',
              gap: '1rem',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.75rem',
                color: (t) => t.palette.text.primary,
                fontWeight: 600,
                lineHeight: '1rem',
                marginBottom: '1rem',
              }}
            >
              {footerData.contactUs.heading}
            </Typography>
            {footerData.contactUs.items.map((item) => {
              return (
                <AppLink
                  style={{ textDecoration: 'none' }}
                  passHref
                  href={item.link ?? ''}
                  key={item.title}
                >
                  <Typography
                    component={Link}
                    sx={{
                      fontSize: '0.75rem',
                      textDecoration: 'none',
                      color: (t) => t.palette.iconDefault.main,
                      fontWeight: 400,
                      lineHeight: '1rem',
                    }}
                    key={item.id}
                  >
                    {item.title}
                  </Typography>
                </AppLink>
              );
            })}
            <Stack
              sx={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center',
                marginTop: '4.12rem',
                gap: '1.63rem',
              }}
            >
              {socialData.reverse().map((item) => {
                const Icon = item.icon;
                return (
                  <AppLink
                    target="_blank"
                    href={item.link}
                    passHref
                    key={`social-${item.title}-desktop`}
                  >
                    <Link>
                      <Icon />
                    </Link>
                  </AppLink>
                );
              })}
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'start',
              gap: '1.58rem',
              width: '20%',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.75rem',
                color: (t) => t.palette.text.primary,
                fontWeight: 600,
                lineHeight: '1rem',
              }}
            >
              دریافت اپلیکیشن کارنامه
            </Typography>
            <AppLink
              passHref
              target="_blank"
              href="https://cdn.karnameh.com/app-versions/karnameh-my-car-latest.apk"
            >
              <Link
                target="_blank"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  borderRadius: '0.25rem',
                  border: '1px solid #00A754',
                  width: '8.4rem',
                  height: '2.5rem',
                  gap: '0.25rem',
                  textDecoration: 'none',
                }}
              >
                <Download />
                <Typography
                  sx={{
                    fontSize: '0.75rem',
                    color: (t) => t.palette.primary.main,
                    fontWeight: 400,
                    lineHeight: '1rem',
                  }}
                >
                  دانلود مستقیم
                </Typography>
              </Link>
            </AppLink>
            <AppLink
              passHref
              target="_blank"
              href="https://cafebazaar.ir/app/com.karnameh"
            >
              <Link
                target="_blank"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  textDecoration: 'none',
                  width: '8.4rem',
                  height: '2.5rem',
                  gap: '0.25rem',
                }}
              >
                <Stack
                  sx={{ width: '100%', height: '100%', position: 'relative' }}
                >
                  <Image
                    sizes="100%"
                    layout="fill"
                    src={bazarBtn}
                    alt="captured-image"
                  />
                </Stack>
              </Link>
            </AppLink>
            <AppLink
              passHref
              target="_blank"
              href="https://myket.ir/app/com.karnameh"
            >
              <Link
                target="_blank"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  textDecoration: 'none',
                  width: '8.4rem',
                  height: '2.5rem',
                }}
              >
                <Stack
                  sx={{ width: '100%', height: '100%', position: 'relative' }}
                >
                  <Image
                    sizes="100%"
                    layout="fill"
                    src={myketButton}
                    alt="captured-image"
                  />
                </Stack>
              </Link>
            </AppLink>
          </Stack>
        </Stack>
        <Typography
          sx={{
            width: '100%',
            fontSize: '0.75rem',
            color: (t) => t.palette.text.primary,
            fontWeight: 600,
            lineHeight: '1rem',
            mt: '2rem',
            paddingTop: '1.5rem',
            borderTop: '1px solid #EBEBEB',
          }}
        >
          چرا به کارنامه اعتماد کنیم؟
        </Typography>
        <Typography
          sx={{
            width: '100%',
            fontSize: '0.75rem',
            color: (t) => t.palette.iconDefault.main,
            fontWeight: 400,
            lineHeight: '1rem',
            mt: '0.5rem',
          }}
        >
          کارنامه ارائه‌دهنده خدمات تخصصی خودرو مانند کارشناسی، خرید و فروش،
          سرویس دوره‌ای و خرید اقساطی است.علاوه بر این، برای اطلاع از قیمت روز
          خودرو، تخمین قیمت خودرو کارکرده و کارشناسی موتورسیکلت نیز می‌توانید
          روی کارنامه حساب کنید.
        </Typography>
      </Stack>
      <FooterSigns />
    </Stack>
  );
};

const Footer: FC = () => {
  // if (isTwaApp()) {
  //   return null;
  // }

  return (
    <Container
      sx={{
        maxWidth: '100% !important',
        width: '100%',
        backgroundColor: 'white',
        marginY: '0 !important',
        padding: '0 !important',
        '@media print': {
          display: 'none',
        },
      }}
    >
      <Container sx={styles.footerContainer}>
        <MobileFooter />
        <DesktopFooter />
      </Container>
    </Container>
  );
};

export default Footer;
