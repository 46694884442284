export const statics = {};
export const emptyPages = [
  '/loan/rules',
  '/services/car-inspection/reserve-car-inspection',
  '/offer',
  '/services/car-sell/order',
  '/services/car-services',
  '/services/car-authentication',
  '/divar-inspection',
  '/buy-price-report/',
  '/price-report',
  '/services/leasing/validation/',
  '/services/leasing/documents/',
  '/car-services/battery',
  '/services/ad-authentication',
  '/services/buy-affidavit',
  '/services/trade-price-report',
  '/services/car-quick-sell',
  '/services/companion-inspector/',
  '/commition-proforma',
  '/services/proforma/',
  '/services/car-exchange',
  '/services/car-exchange',
  '/services/divar-leasing',
  '/user-profile/sell/details',
  '/user-profile/leasing/details',
  '/services/leasing/seller-document',
  '/profile/sell/details',
  '/services/car-violation',
  '/divar-widgets',
  '/services/car-post-funnel/',
];
