import axios from 'axios';
import { REGEX_PHONE_NUMBER } from 'constants/regex';
import { useFormik } from 'formik';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useLogin, {
  loginWithModalResolveReject,
  setLoginWithModalResolveReject,
} from 'hooks/useLogin';
import { ChangeEvent, useCallback, useState } from 'react';
import {
  loginSetLoggingPhoneNumber,
  loginSetState,
} from 'states/components-slices/authSlice';
import { faNumToEn } from 'utils/en-fa';

const useLogic = () => {
  const phoneNumber = useAppSelector((store) => store.auth.loggingPhoneNumber);

  // this is used to prefill the TextFeild
  const loggedInPhoneNumber = useAppSelector(
    (store) => store.auth.data.phoneNumber,
  );
  const { sendOtp: submitPhoneNumber, isLoading } = useLogin();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phoneNumber: phoneNumber || loggedInPhoneNumber || '',
    },
    onSubmit: (values) => {
      if (REGEX_PHONE_NUMBER.test(values.phoneNumber) && !isLoading) {
        setErrorMessage('');
        submitPhoneNumber(values.phoneNumber)
          .then(() => {
            dispatch(loginSetLoggingPhoneNumber(values.phoneNumber));
            dispatch(loginSetState('otp'));
          })
          .catch((err) => {
            if (axios.isAxiosError(err) && err.response?.status === 429) {
              setErrorMessage('تعداد درخواست‌های شما محدود شده است.');
            } else {
              setErrorMessage('خطایی رخ داده است. مجددا تلاش کنید.');
            }
          });
      }
    },
  });

  const canSubmit =
    REGEX_PHONE_NUMBER.test(formik.values.phoneNumber) && !isLoading;

  // allowing just numbers for phoneNumber TextFeild
  const phoneChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.target.value.length > 11) {
        return;
      }

      const newPhoneNumber = faNumToEn(
        event.target.value.replace(/[^0-9۰-۹]/g, ''),
      );

      formik.setFieldValue(event.target.name, newPhoneNumber);
    },
    [formik],
  );

  const closeModal = useCallback(() => {
    dispatch(loginSetLoggingPhoneNumber(''));
    dispatch(loginSetState('closed'));
    if (loginWithModalResolveReject) {
      loginWithModalResolveReject.reject();
    }
    setLoginWithModalResolveReject(null);
  }, [dispatch]);

  return {
    errorMessage,
    formik,
    canSubmit,
    phoneChangeHandler,
    closeModal,
  };
};

export default useLogic;
