import createStyle from 'utils/createStyle';

const styles = createStyle({
  backLayer: {
    width: '100%',
    height: '100dvh',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: {
      xs: (th) => th.palette.error.main,
      md: '#00000047',
    },
    transition: 'opacity 0.3s',
    zIndex: 10,
  },
  modalContainer: {
    width: { xs: '100%', md: 'calc(100% - 2rem)' },
    maxWidth: { xs: 'unset', md: '40rem' },
    top: { xs: 0, md: '50%' },
    left: { xs: 0, md: '50%' },
    height: '100dvh',
    maxHeight: { xs: 'unset', md: `min(calc(100dvh - 2rem), 30rem)` },
    position: 'fixed',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: (th) => th.palette.surface.default,
    transition: 'opacity 0.3s, transform 0.3s ease-out',
    borderRadius: { xs: 'unset', md: '2.5rem' },
    zIndex: 10,
    overflow: 'auto',
  },
  header: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    py: '0.5rem',
    px: '1rem',
    borderBottom: (th) => `1px solid ${th.palette.surface.paper}`,
    flexDirection: 'row',
  },
  imageWrapper: {
    width: '50%',
    flexBasis: '50%',
    display: { xs: 'none', md: 'flex' },
    alignSelf: 'stretch',
    overflow: 'hidden',
    backgroundColor: (th) => th.palette.surface.default,
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contents: {
    width: { xs: '100%', md: '50%' },
    flexBasis: { xs: '100%', md: '50%' },
    alignSelf: 'stretch',
  },
});

export default styles;
