import { Phone } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { receiptTextStyles } from 'containers/car-services-order/configs';
import { PropsWithChildren, forwardRef, useEffect, useState } from 'react';

type Props = {
  onClick: () => void;
  isGuideVisible?: boolean;
  id?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  locateBaseOnBottom?: boolean;
  buttonText?: string;
};

const Footer = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  (
    {
      id,
      onClick,
      isGuideVisible,
      isLoading,
      isDisabled,
      locateBaseOnBottom = false,
      children,
      buttonText = ' تایید و ادامه',
    },
    ref,
  ) => {
    const theme = useTheme();
    const [vh, setVh] = useState(0);

    useEffect(() => {
      const windowResizeHandler = () => {
        setVh(window.visualViewport?.height ?? window.innerHeight);
      };

      windowResizeHandler();

      window.addEventListener('resize', windowResizeHandler);
      return () => {
        window.removeEventListener('resize', windowResizeHandler);
      };
    }, []);

    return (
      <Stack
        sx={{
          position: locateBaseOnBottom ? 'absolute' : 'fixed',
          top: locateBaseOnBottom ? 'initial' : `${vh}px`,
          transform: locateBaseOnBottom ? 'initial' : 'translateY(-100%)',
          bottom: locateBaseOnBottom ? '0' : 'initial',
          left: '0',
          right: '0',
          padding: { md: '0 2.5rem' },
          width: { xs: '100%', md: '55%' },
          zIndex: 1,
          backgroundColor: theme.palette.common.white,
        }}
      >
        {isGuideVisible && (
          <Stack
            sx={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0.5rem',
              marginBottom: '1.5rem',
              color: theme.palette.secondary.main,
              display: { xs: 'none', md: 'flex' },
            }}
            component="a"
            href="tel:02179237"
          >
            <Typography sx={{ ...receiptTextStyles.title }}>
              نیاز به مشاوره دارم:
            </Typography>

            <Phone
              sx={{
                color: theme.palette.secondary.main,
                width: '1.25rem',
                height: '1.25rem',
              }}
            />
            <Typography component="bdi" sx={{ ...receiptTextStyles.title }}>
              ۰۲۱ - ۷۹ ۲۳۷
            </Typography>
          </Stack>
        )}
        <Stack
          sx={{
            padding: { xs: '1rem 1.5rem', md: '1.5rem 0' },
            borderTop: `1px solid ${theme.palette.surface.paper}`,
            backgroundColor: theme.palette.common.white,
            width: '100%',
          }}
        >
          {children}
          <Button
            ref={ref}
            disabled={isDisabled}
            onClick={onClick}
            id={id}
            sx={{
              alignSelf: { md: 'end' },
              minWidth: { md: '12.5rem' },
              height: '2.5rem',
            }}
          >
            {isLoading ? (
              <CircularProgress sx={{ padding: '0.35rem' }} />
            ) : (
              buttonText
            )}
          </Button>
        </Stack>
      </Stack>
    );
  },
);

Footer.displayName = 'Footer';

export default Footer;
