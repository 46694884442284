import { Call } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import Image from 'next/image';
import logo from '../../../../public/assets/logos/karnameh-logo-new.svg';

interface HeaderLogoInterface {
  href?: string;
  hasTell?: boolean;
}

function HeaderLogo({ href, hasTell = false }: HeaderLogoInterface) {
  return (
    <Stack flexDirection="column" justifyContent="start" alignItems="center">
      <Link href={href} display={'flex'} alignItems={'center'}>
        <Image
          src={logo}
          alt="کارنامه کارشناسی خودرو"
          height={25.76}
          width={104}
        />
      </Link>
      {hasTell && (
        <Link
          display="flex"
          flexDirection="row"
          mt="1rem"
          gap="0.31rem"
          sx={{ textDecoration: 'none' }}
          href="tel:02179237"
        >
          <Call />
          <Typography>021-79237</Typography>
        </Link>
      )}
    </Stack>
  );
}

export default HeaderLogo;
