import { InfoOutlined } from '@mui/icons-material';
import { IconButton, Radio, Stack, Typography } from '@mui/material';
import { BundleDescriptionModal } from 'containers/car-services-order/components/BundleDescriptionModal';
import { receiptTextStyles } from 'containers/car-services-order/configs';
import { useState } from 'react';
import { TierType } from 'services/rest/car-services/car-services';

const InfoDescriptions: {
  [key in TierType]: { title: string; description: string };
} = {
  customization: {
    title: 'بسته انتخابی',
    description: 'از بین قطعات پیشنهادی، قطعه مورد نظر خود را انتخاب کنید.',
  },
  economy: {
    title: 'بسته اقتصادی',
    description:
      'این بسته شامل کم هزینه‌ترین قطعاتی است که کیفیت قابل قبولی برای خودروی شما دارند.',
  },
  premium: {
    title: '',
    description: '',
  },
  standard: {
    title: 'بسته استاندارد ',
    description:
      'این بسته شامل قطعاتی است که بطور معمول برای خودروی شما استفاده می‌شود.',
  },
};

type PackageHeaderProps = {
  totalPrice: number;
  price: number;
  discount?: number;
  tierItem: TierType;
  onTierChanged: (value: TierType) => void;
  selectedTier: TierType | undefined;
};

export const BundleHeader = ({
  totalPrice,
  discount,
  tierItem,
  onTierChanged,
  selectedTier,
  price,
}: PackageHeaderProps) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Stack
      sx={{
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Stack flexDirection="row" alignItems="center">
        <Radio
          onChange={(e) => {
            onTierChanged(e.target.value as TierType);
          }}
          sx={{
            width: '100%',
            '&.Mui-checked': {
              color: '#694FB4',
            },
          }}
          checked={selectedTier === tierItem}
          value={tierItem}
        />
        <Typography sx={{ ...receiptTextStyles.header, minWidth: '70px' }}>
          {tierItem === 'standard'
            ? 'استاندارد'
            : tierItem === 'economy'
            ? 'اقتصادی'
            : 'انتخابی'}
        </Typography>
        <IconButton
          component={InfoOutlined}
          onClick={() => setOpen(true)}
          sx={{
            color: (theme) => theme.palette.info.main,
            width: '1.5rem',
            height: '1.5rem',
            marginLeft: '1.5rem',
            padding: 0,
          }}
        />
      </Stack>
      <Stack>
        {discount && price !== 0 ? (
          <Stack
            sx={{
              flexDirection: 'row',
              gap: '0.25rem',
              alignItems: 'center',
              alignSelf: 'end',
              marginBottom: '0.5rem',
            }}
          >
            <Typography
              sx={{
                ...receiptTextStyles.title,
                fontWeight: 'bold',
                color: (theme) => theme.palette.iconDefault.main,
                textDecoration: 'line-through',
              }}
            >
              {`${price.toLocaleString()} تومان`}
            </Typography>
            <Typography
              sx={{
                borderRadius: '0.25rem',
                backgroundColor: (theme) => theme.palette.error.main,
                color: (theme) => theme.palette.common.white,
                padding: '0 0.25rem',
                fontSize: '0.6875rem',
                fontWeight: 600,
                lineHeight: '1rem',
                textAlign: 'center',
              }}
            >
              {`${
                discount >= 2
                  ? `${discount.toFixed(1)}%`
                  : `${(totalPrice - price).toLocaleString()} تومان`
              }`}
              <br />
            </Typography>
          </Stack>
        ) : (
          <></>
        )}
        <Typography fontWeight="bold" alignSelf="end">
          {price === 0 ? '-' : price.toLocaleString()}
          <Typography component="span" sx={{ marginLeft: '0.25rem' }}>
            تومان
          </Typography>
        </Typography>
      </Stack>
      <BundleDescriptionModal
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        title={InfoDescriptions[tierItem].title}
        description={InfoDescriptions[tierItem].description}
      />
    </Stack>
  );
};
