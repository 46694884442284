import {
  MutationFunction,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  useInfiniteQuery,
  useMutation,
} from '@tanstack/react-query';
import { GetServerSidePropsContext } from 'next';
import { ParsedUrlQuery, stringify } from 'querystring';
// import { PAGE_SIZE } from 'containers/buy-used-cars/config';
import { apiClient } from 'services/clients';
import { api2Endpoints } from 'services/configs';

export const getPLPList = (
  params: GetPLPListParams,
  context?: GetServerSidePropsContext,
) => {
  const queryString = stringify(params as ParsedUrlQuery);
  return apiClient<GetPLPListResponse>(
    {
      method: 'GET',
      url: `${api2Endpoints.LATEST_ADS_WITHOUT_PARAMS}${
        queryString ? `?${queryString}` : ''
      }`,
    },
    context?.req || undefined,
  ).then((res) => {
    return res.data;
  });
};

const getPLPListKey = 'getPLPList';

export const getPlpListQueryKey = (params: GetPLPListParams) => [
  'getPLPListQuery',
  params.brand_models || 'brand_models',
  params.brands || 'brands',
  params.models || 'models',
  params.types || 'types',
  params.cities || 'cities',
  params.search_query || 'search_query',
  params.should_attach_inspection_report || 'should_attach_inspection_report',
  params.is_leasing_available || 'is_leasing_available',
  params.widget_label || 'widget_label',
  params.prepayment_amount || 'prepayment_amount',
  params.max_year || 'max_year',
  params.min_year || 'min_year',
  params.max_usage || 'max_usage',
  params.min_price || 'min_price',
  params.max_price || 'max_price',
  params.min_prepayment || 'min_prepayment',
  params.max_prepayment || 'max_prepayment',
  params.sort || 'sort',
];

export const useGetPLPListQuery = (
  params: GetPLPListParams,
  options?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getPLPList>>>,
) => {
  const queryFn = ({ pageParam: start = 0 }) => {
    params.start = start || 0;
    return getPLPList(params);
  };

  return useInfiniteQuery({
    queryFn,
    queryKey: getPlpListQueryKey(params),
    getNextPageParam: (lastPage) => {
      if (lastPage.total <= lastPage.start + lastPage.size) {
        return undefined;
      } else {
        return lastPage.start + lastPage.size;
      }
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    ...options,
  });
};

export const useGetPLPListMutate = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof getPLPList>>,
    unknown,
    Parameters<typeof getPLPList>[0]
  >,
) => {
  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getPLPList>>,
    Parameters<typeof getPLPList>[0]
  > = (params) => {
    return getPLPList(params);
  };

  const mutationKey = [getPLPListKey];

  return useMutation({
    mutationFn,
    mutationKey,
    ...options,
  });
};
